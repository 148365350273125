import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FaUserCircle } from "react-icons/fa";
import { toast } from "react-toastify";

const Navbar = ({ setIsAuth,logo }) => {
  const navigate = useNavigate();
  const [dropdownOpen, setDropdownOpen] = useState(false);
 
  const handleLogout = () => {
    localStorage.removeItem("accessToken");
    toast.success("User logged out");
    setIsAuth(false);
    navigate("/auth");
  };

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const[usercredits,setUserCredits]=useState(0);
  const[expiryDate,setNewCreditExpiry]=useState(null);
  const userDataString = localStorage.getItem("user");

  useEffect(()=>{
    if (userDataString) {
      try {
        const userData = JSON.parse(userDataString); // Parse the JSON string
        const credits = userData?.credits; // Access the credits property
        const expiry = userData?.expired_at;
        setUserCredits(credits);
        setNewCreditExpiry(expiry);
      } catch (error) {
        console.error("Error parsing user data from localStorage:", error);
      }
    } else {
      console.log("User data not found in localStorage.");
    }
  },[])


  return (
    <nav className="flex justify-between items-center p-4 bg-white text-purple-600 border-b border-gray-300">
      <Link to="/inbound-sms" className="flex items-center">
        <img src={logo} alt="Logo" className="h-8" />
      </Link>
      <div className="flex items-center gap-4">
        <div className="flex gap-x-4">
          <p className="text-black font-semibold  ">Credits: {usercredits?usercredits:0}</p>
          {
            expiryDate?<p className="text-black font-semibold  ">Expires At: {expiryDate}</p>:null
          }
          
        </div>
        <div className="relative">
          <button onClick={toggleDropdown} className="focus:outline-none">
            <FaUserCircle className="text-3xl" />
          </button>
          {dropdownOpen && (
            <div className="absolute right-0 mt-2 w-48 bg-white text-black rounded-md shadow-lg z-10">
              <Link to="/profile">
                <div className="py-2 px-4 hover:bg-purple-100 cursor-pointer">
                  Profile
                </div>
              </Link>
              <div
                className="py-2 px-4 hover:bg-purple-100 cursor-pointer"
                onClick={handleLogout}
              >
                Logout
              </div>
            </div>
          )}
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
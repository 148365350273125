import axios from "axios";
import { apiConfig } from "../apiConfig";

const getUsers = async () => {
    const accessToken = localStorage.getItem("accessToken");
    try {
        const res = await axios.get(`${apiConfig.baseURL}/nissen/v1/users`, {
            headers: {
                "Authorization": `Bearer ${accessToken}`,
            },
        });

        return res.data; 
    } catch (e) {
        console.log('Error fetching users:', e.message); 
        throw e; 
    }
};

const getUser = async (userId) => {
  try {
    const response = await fetch(`/api/users/${userId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return await response.json();
  } catch (error) {
    console.error('Error fetching user:', error);
    throw error;
  }
};

const addUser = async ({ payload }) => {
    const accessToken = localStorage.getItem("accessToken");
    try {
       const res = await axios.post(`${apiConfig.baseURL}/nissen/v1/user`,payload,{
        headers:{
            "Authorization": `Bearer ${accessToken}`,
        }
    })
      return res.data;
    } catch (e) {
      console.log('Error adding new user:', e.message);
      throw e;
    }
}

const updateUser = async ({ payload }) => {
    const accessToken = localStorage.getItem("accessToken");
    try {
        const url = `${apiConfig.baseURL}/nissen/v1/user`;
        const res = await axios.patch(url, payload, {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        });

        return res.data; 
    } catch (e) {
        console.log('Error updating user:', e.message);
        throw e;
    }
};

const deleteUser = async (user) => {
  const accessToken = localStorage.getItem("accessToken");
  try {
    const url = `${apiConfig.baseURL}/nissen/v1/user`;
    const res = await axios.delete(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      data: user,
    });

    return res.data;
  } catch (e) {
    console.log('Error deleting user:', e.message);
    throw e;
  }
};

const getInboundSMS = async () => {
    const accessToken = localStorage.getItem("accessToken");
    try {
      const url = `${apiConfig.baseURL}/nissen/v1/inbound-sms`;
      const res = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
  
      return res.data;
    } catch (e) {
      console.log('Error fetching inbound SMS:', e.message);
      throw e;
    }
  };
  
const putLogoandTitle = async (data) =>{
  const accessToken = localStorage.getItem("accessToken");
  try {
    const url = `${apiConfig.baseURL}/nissen/v1/logo-title`;
    const res = await axios.put(url, data,{
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return res.data;
  } catch(e){
    console.log('Error updating logo and title:', e.message);
    throw e;
  }
}
const getLogoAndTitle = async () => {
  const accessToken = localStorage.getItem("accessToken");
  try {
    const url = `${apiConfig.baseURL}/nissen/v1/logo-title`;
    const res = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return res.data.data;
  } catch (e) {
    console.log('Error fetching logo and title:', e.message);
    throw e;
  }
};

const getUserCredits = async (user_id) => {
  const accessToken = localStorage.getItem("accessToken");
  try {
    const url = `${apiConfig.baseURL}/nissen/v1/user-credit?user_id=${user_id}`;
    const res = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return res.data.data;
  } catch (e) {
    console.log('Error fetching user credits:', e.message);
    throw e;
  }
};
const addCreditsToUser = async (data) => {
  const accessToken = localStorage.getItem("accessToken");

  let config = {
    method: 'patch',
    maxBodyLength: Infinity,
    url: 'https://smsapi.callnfax.com/nissen/v1/user-credit',
    headers: { 
      'Content-Type': 'application/json', 
      'Authorization': `Bearer ${accessToken}`
    },
    data : data
  };
  
  return axios.request(config)
  .then((response) => {
   return response.data;
  })
  .catch((error) => {
    console.log(error);
  });
  
};


const getRechargeLog = async (userId) => {
  const accessToken = localStorage.getItem("accessToken");
  try {
    const url = `${apiConfig.baseURL}/nissen/v1/history/user-credit`;
    const res = await axios.get(url, {
      params: {
        query: userId,
      },
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`,
      },
    });

    return res.data.data;
  } catch (e) {
    console.log('Error fetching recharge log:', e.message);
    throw e;
  }
};

export { getUsers,getUser,addUser,updateUser,deleteUser,addCreditsToUser,getInboundSMS,putLogoandTitle,getLogoAndTitle,getUserCredits,getRechargeLog};
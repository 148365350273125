import "./App.css";
import { useState, useEffect } from "react";
import { Routes, Route, useLocation, Navigate, useNavigate } from "react-router-dom";
import Auth from "./pages/Auth/Auth";
import PasswordRecovery from "./pages/Auth/PasswordRecovery";
import InnerRoute from "./InnerRoute";
import Navbar from "./components/Navbar";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PasswordReset from "./pages/Auth/PasswordReset";
import { getLogoAndTitle } from "./services/users/user";
import {jwtDecode} from "jwt-decode";

function App() {
  const location = useLocation();
  const navigate = useNavigate();
  const [isAuth, setIsAuth] = useState(false);
  const [logo, setLogo] = useState("");

  const isAuthRoute = location.pathname === '/auth' || location.pathname === '/password-recovery' || location.pathname === '/nissen/v1/reset-password';

  const loadLogoAndTitle = async () => {
    try {
      const response = await getLogoAndTitle();
      if (response.logo && response.title) {
        const link = document.querySelector("link[rel='icon']");
        link.href = `data:image/png;base64,${response.logo}`;
        document.title = response.title;
        setLogo(`data:image/png;base64,${response.logo}`);
      }
    } catch (error) {
      console.error("Error fetching logo and title:", error);
    }
  };

  useEffect(() => {
    loadLogoAndTitle();
  }, []);

  const checkTokenExpiration = () => {
    const token = localStorage.getItem("accessToken");
    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        const currentTime = Date.now() / 1000;
        if (decodedToken.exp < currentTime) {
          // Token expired, remove it and return false
          localStorage.removeItem("accessToken");
          setIsAuth(false);
          navigate("/auth");
          return false;
        } else {
          // Token is valid, set isAuth to true
          setIsAuth(true);
          return true;
        }
      } catch (error) {
        console.error("Error decoding token:", error);
        localStorage.removeItem("accessToken");
        setIsAuth(false);
        navigate("/auth");
        return false;
      }
    } else {
      // No token, return false
      setIsAuth(false);
      return false;
    }
  };

  useEffect(() => {
    if (!isAuthRoute) {
      const isValidToken = checkTokenExpiration();
      if (!isValidToken) {
        navigate("/auth");
      }
    }
  }, [navigate, isAuthRoute]);

  return (
    <div className="App">
      {isAuth && location.pathname !== '/auth' && <Navbar setIsAuth={setIsAuth} logo={logo} />}
      <ToastContainer />
      <Routes>
        {isAuth ? (
          <>
            <Route path="/" element={<Navigate to="/inbound-sms" />} />
            <Route path="/*" element={<InnerRoute logo={logo} setLogo={setLogo} />} />
          </>
        ) : (
          <>
            <Route path="/" element={<Navigate to="/auth" />} />
            <Route path="/auth" element={<Auth setIsAuth={setIsAuth} />} />
            <Route path="/password-recovery" element={<PasswordRecovery />} />
            <Route path="/nissen/v1/reset-password" element={<PasswordReset />} />
          </>
        )}
      </Routes>
    </div>
  );
}

export default App;
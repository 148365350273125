import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { getUserCredits, updateUser, getRechargeLog, addCreditsToUser } from "../../services/users/user";
import { forgotPassword } from "../../services/auth/auth";
import { toast } from "react-toastify";
import { FaHistory } from "react-icons/fa";

const UserModalPage = ({ selectedUser }) => {
  const { userId } = useParams();
  const navigate = useNavigate();
  const [newUserDetails, setNewUserDetails] = useState({
    id: selectedUser.id,
    username: selectedUser.username,
    email: selectedUser.email,
    trunk: selectedUser.trunk,
    role: selectedUser.role,
    status: selectedUser.status,
  });
  const [passwordResetMessage, setPasswordResetMessage] = useState("");
  const [userCredits, setUserCredits] = useState(null);
  const [isAddingCredits, setIsAddingCredits] = useState(false);
  const [newCredits, setNewCredits] = useState("");
  const [newCreditExpiry, setNewCreditExpiry] = useState("");
  const [rechargeHistory, setRechargeHistory] = useState([]);

  useEffect(() => {
    const fetchUserCredits = async () => {
      try {
        const data = await getUserCredits(userId);
        setUserCredits(data);
      } catch (err) {
        console.error("Error fetching user credits:", err);
        toast.error("Error fetching user credits.", { autoClose: 2000 });
      }
    };

    fetchUserCredits();
  }, [userId,isAddingCredits]);

  useEffect(() => {
    const fetchRechargeHistory = async () => {
      try {
        const data = await getRechargeLog(userId);
        setRechargeHistory(data);
      } catch (err) {
        console.error("Error fetching recharge history:", err);
        toast.error("Error fetching recharge history.", { autoClose: 2000 });
      }
    };

    fetchRechargeHistory();
  }, [userId,userCredits]);

  const handleAddCredits = async (credits, expiry) => {
   try {
     if(!userId || !credits || !expiry) {
        toast.error("Please fill all fields.", { autoClose: 2000 });
        return;
      }
    
      const data ={
        id:  parseInt(userId,10),
        limit : parseInt(credits,10),
        expired_at : new Date(expiry).toISOString(),
      }
      const res = await addCreditsToUser(data);
      if (res?.success) {
     
        toast.success("Credits added successfully!", { autoClose: 2000 });
      } else {
        toast.error("Failed to add credits. Please try again.", {
          autoClose: 2000,
        });
      }

   } catch (error) {
     console.error("Error adding credits:", error);
     toast.error("Error adding credits.", { autoClose: 2000 });
    
   }

  };

  const handleConfirm = () => {
    handleAddCredits(newCredits, newCreditExpiry);
    setIsAddingCredits(false);
    setNewCreditExpiry("")
    setNewCredits("");
   
  };

  const handleInputChange = (e) => {
    setNewUserDetails({
      ...newUserDetails,
      [e.target.name]: e.target.value,
    });
  };

  const handleUpdateUser = async () => {
    try {
      const res = await updateUser({ id: userId, payload: newUserDetails });
      if (res.success) {
        toast.success("User updated successfully!", { autoClose: 2000 });
        navigate("/users");
      } else {
        toast.error("Failed to update user. Please try again.", {
          autoClose: 2000,
        });
      }
    } catch (err) {
      console.error("Error updating user:", err);
      toast.error("Error updating user.", { autoClose: 2000 });
    }
  };

  const handleResetPassword = async () => {
    try {
      const res = await forgotPassword({ username: selectedUser.username });
      if (res.success) {
        setPasswordResetMessage("Password reset link sent to user's email.");
        toast.success("Password reset link sent!", { autoClose: 2000 });
      } else {
        setPasswordResetMessage("Failed to send reset link. Try again.");
        toast.error("Failed to send reset link.", { autoClose: 2000 });
      }
    } catch (err) {
      console.error("Error sending reset link:", err);
      setPasswordResetMessage("Error occurred. Try again.");
      toast.error("Error sending reset link.", { autoClose: 2000 });
    }
  };

  if (!selectedUser) return <div>Loading...</div>;

  return (
    <div className="p-8">
      <div className="flex justify-between items-center">
        <h2 className="text-center text-2xl font-bold mb-4">
          Edit User Details
        </h2>
        <div className="flex gap-8 items-center justify-evenly">
          <p className="font-medium text-gray-600">
            Credits: {" "}
            <span className=" text-gray-800">{userCredits?.credit}</span>
          </p>
          <p className="font-medium text-gray-600">
            Expiry Time:{" "}
            <span className="text-gray-800">
              {new Date(userCredits?.expired_at).toLocaleString("en-US", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
                hour: "2-digit",
                minute: "2-digit",
                hour12: true,
              })}
            </span>
          </p>
          <button
            onClick={() => setIsAddingCredits(true)}
            className="w-1/8 bg-blue-500 hover:bg-blue-600 text-white font-semibold py-1.5 px-4 rounded transition duration-300 ease-in-out"
          >
            Add Credits
          </button>
        </div>
      </div>
      {isAddingCredits && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-md">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-xl font-semibold text-gray-800">
                Add Credits
              </h2>
              <button
                onClick={() => setIsAddingCredits(false)}
                className="text-gray-600 hover:text-gray-800 transition-colors duration-300"
              >
                ← Back
              </button>
            </div>
            <div className="space-y-4 mb-6">
              <div>
                <label
                  htmlFor="credits"
                  className="block text-sm font-medium text-gray-700 mb-1"
                >
                  Credits
                </label>
                <input
                  type="number"
                  id="credits"
                  value={newCredits}
                  onChange={(e) => setNewCredits(e.target.value)}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                  placeholder="Enter credits"
                />
              </div>
              <div>
                <label
                  htmlFor="expiry"
                  className="block text-sm font-medium text-gray-700 mb-1"
                >
                  Expiry Time
                </label>
                <input
                  type="datetime-local"
                  id="expiry"
                  value={newCreditExpiry}
                  onChange={(e) => setNewCreditExpiry(e.target.value)}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
              </div>
            </div>
            <button
              onClick={handleConfirm}
              className="w-full bg-green-500 hover:bg-green-600 text-white font-semibold py-2 px-4 rounded transition duration-300 ease-in-out"
            >
              Confirm
            </button>
          </div>
        </div>
      )}

      <div className="md:flex gap-10">
        <div className="w-full md:w-2/3 bg-white">
          <div className="mb-4">
            <label className="block text-gray-700">Username</label>
            <input
              type="text"
              name="username"
              value={selectedUser.username}
              className="w-full p-2 border border-gray-300 rounded-md"
              disabled
            />
          </div>

          <div className="mb-4">
            <label className="block text-gray-700">Trunk</label>
            <input
              type="text"
              name="trunk"
              value={newUserDetails.trunk}
              onChange={handleInputChange}
              className="w-full p-2 border border-gray-300 rounded-md"
            />
          </div>

          <div className="mb-4">
            <label className="block text-gray-700">Email</label>
            <input
              type="email"
              name="email"
              value={newUserDetails.email}
              onChange={handleInputChange}
              className="w-full p-2 border border-gray-300 rounded-md"
            />
          </div>

          <div className="mb-4">
            <label className="block text-gray-700">Role</label>
            <div className="flex items-center space-x-4">
              <label className="inline-flex items-center">
                <input
                  type="radio"
                  name="role"
                  value="admin"
                  checked={newUserDetails.role === "admin"}
                  onChange={handleInputChange}
                  className="form-radio text-blue-600"
                />
                <span className="ml-2">Admin</span>
              </label>
              <label className="inline-flex items-center">
                <input
                  type="radio"
                  name="role"
                  value="user"
                  checked={newUserDetails.role === "user"}
                  onChange={handleInputChange}
                  className="form-radio text-blue-600"
                />
                <span className="ml-2">User</span>
              </label>
            </div>
          </div>

          <div className="mb-4">
            <label className="block text-gray-700">Status</label>
            <div className="flex items-center space-x-4">
              <label className="inline-flex items-center">
                <input
                  type="radio"
                  name="status"
                  value="enabled"
                  checked={newUserDetails.status === true}
                  onChange={handleInputChange}
                  className="form-radio text-blue-600"
                />
                <span className="ml-2">Enabled</span>
              </label>
              <label className="inline-flex items-center">
                <input
                  type="radio"
                  name="status"
                  value="disabled"
                  checked={newUserDetails.status === false}
                  onChange={handleInputChange}
                  className="form-radio text-blue-600"
                />
                <span className="ml-2">Disabled</span>
              </label>
            </div>
          </div>

          <div className="mb-4">
            <button
              onClick={handleResetPassword}
              className="font-semibold py-2 underline text-blue-600 rounded-md transition duration-300 ease-in-out"
            >
              Reset Password
            </button>
            {passwordResetMessage && (
              <p className="mt-2 text-sm text-green-600">
                {passwordResetMessage}
              </p>
            )}
          </div>

          <button
            onClick={handleUpdateUser}
            className="w-full bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded-md transition duration-300 ease-in-out"
          >
            Update User
          </button>
        </div>
        <div className="md:w-1/2 py-5">
          <div className="flex justify-between items-center mb-6">
            <h1 className="text-2xl font-medium">Recharge History</h1>
            <FaHistory className="text-xl text-gray-600" />
          </div>

          <div className="overflow-x-auto ">
            <table className="min-w-full table-auto border-collapse border border-gray-300">
              <thead>
                <tr className="bg-gray-100">
                  <th className="border border-gray-300 px-4 py-2 text-left text-sm font-medium text-gray-700">
                    Credits
                  </th>
                  <th className="border border-gray-300 px-4 py-2 text-left text-sm font-medium text-gray-700">
                    Expiry Time
                  </th>
                  <th className="border border-gray-300 px-4 py-2 text-left text-sm font-medium text-gray-700">
                    Recharged On
                  </th>
                </tr>
              </thead>
              <tbody>
                {rechargeHistory.map((entry, index) => (
                  <tr
                    key={entry.id}
                    className={`${
                      index % 2 === 0 ? "bg-white" : "bg-gray-50"
                    } hover:bg-gray-200 transition duration-150 ease-in-out`}
                  >
                    <td className="border border-gray-300 px-4 py-2 text-gray-800 text-sm">
                      {entry.credit}
                    </td>
                    <td className="border border-gray-300 px-4 py-2 text-gray-800 text-sm">
                      {new Date(entry.expired_at).toLocaleString()}
                    </td>
                    <td className="border border-gray-300 px-4 py-2 text-gray-800 text-sm">
                      {new Date(entry.created_at).toLocaleString()}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserModalPage;
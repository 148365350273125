import React, { useEffect, useState } from "react";
import { getUsers, addUser, deleteUser, getUserCredits } from "../../services/users/user";
import { toast } from "react-toastify";
import SearchBar from "../../components/SearchBar";
import CreateUserModal from "./CreateUserModal";
import { FaTrash } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const User = ({ setSelectedUser }) => {
  const navigate = useNavigate();
  const [users, setUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [newUser, setNewUser] = useState({
    username: "",
    password: "",
    trunk: "",
    email: "",
  });

  const fetchUsers = async () => {
    try {
      const res = await getUsers();
      setUsers(res.data);
    } catch (err) {
      console.error("Error fetching users:", err);
      toast.error("Error fetching users.", { autoClose: 2000 });
    }
  };

  useEffect(() => {
    fetchUsers();
  }, []);
  
  const filteredUsers = users.filter((user) =>
    user.username.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleAddInputChange = (e) => {
    setNewUser({ ...newUser, [e.target.name]: e.target.value });
  };

  const handleUserClick = (user) => {
    setSelectedUser(user);
    navigate(`/users/${user.id}`);
  };

  const handleAddUser = async () => {
    try {
      const res = await addUser({ payload: newUser });
      if (res.success) {
        toast.success("User created successfully!", { autoClose: 2000 });
        fetchUsers();
        setIsCreateModalOpen(false);
        setNewUser({ username: "", password: "", trunk: "", email: "", role: "user" });
      } else {
        toast.error("Failed to create user. Please try again.", { autoClose: 2000 });
      }
    } catch (err) {
      console.error("Error adding new user:", err);
      toast.error("Error adding new user.", { autoClose: 2000 });
    }
  };

  const handleDeleteUser = async (user) => {
    try {
      const res = await deleteUser(user);
      if (res.success) {
        toast.success("User deleted successfully!", { autoClose: 2000 });
        fetchUsers();
      } else {
        toast.error("Failed to delete user. Please try again.", { autoClose: 2000 });
      }
    } catch (err) {
      console.error("Error deleting user:", err);
      toast.error("Error deleting user.", { autoClose: 2000 });
    }
  };

  return (
    <div className="p-8">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-2xl font-bold mb-4 text-black">Users</h2>
        <div className="flex justify-between items-center">
          <SearchBar placeholder="Search Users..." onSearch={setSearchTerm} />
          <button
            onClick={() => setIsCreateModalOpen(true)}
            className="border border-purple-500 text-purple-500 px-4 py-2 mb-4 ml-2 rounded-md hover:bg-purple-500 hover:text-white"
          >
            Create User
          </button>
        </div>
      </div>

      <div className="overflow-x-auto">
        <table className="min-w-full bg-white border border-gray-300">
          <thead>
            <tr>
              <th className="py-2 px-4">Username</th>
              <th className="py-2 px-4">Email</th>
              <th className="py-2 px-4">User ID</th>
              <th className="py-2 px-4">Created At</th>
              <th className="py-2 px-4">Credits</th>
              <th className="py-2 px-4">Credit Expiry Time</th>
              <th className="py-2 px-4">Status</th>
              <th className="py-2 px-4">Actions</th>
            </tr>
          </thead>
          <tbody>
            {filteredUsers.map((user) => (
              <tr
                key={user.id}
                className="hover:bg-gray-100 hover:cursor-pointer border rounded-full text-center"
                onClick={() => handleUserClick(user)}
              >
                <td className="py-2 px-4">{user.username || "N/A"}</td>
                <td className="py-2 px-4">{user.email || "N/A"}</td>
                <td className="py-2 px-4">{user.trunk || "N/A"}</td>
                <td className="py-2 px-4">
                  {new Date(user.created_at).toLocaleString()}
                </td>
                <td className="py-2 px-4">{user.credit | "N/A" }</td>
                <td className="py-2 px-4">
                  {new Date(user.created_at).toLocaleString() || "N/A"}
                </td>
                <td className="py-2 px-4">
                  <div className="flex items-center justify-center">
                    <span
                      className={`w-3 h-3 rounded-full mr-2 ${
                        user.status ? "bg-green-500" : "bg-red-500"
                      }`}
                    ></span>
                    <span>{user.status ? "Enabled" : "Disabled"}</span>
                  </div>
                </td>
                <td className="py-2 px-4">
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      handleDeleteUser({ username: user.username });
                    }}
                    className="text-red-500 hover:text-red-700"
                  >
                    <FaTrash />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {isCreateModalOpen && (
        <CreateUserModal
          newUser={newUser}
          handleInputChange={handleAddInputChange}
          setIsCreateModalOpen={setIsCreateModalOpen}
          handleAddUser={handleAddUser}
        />
      )}
    </div>
  );
};

export default User;
